import ServerService from './ServerService';
//import router from '@/router';

export default {
  name: 'Minxins',

  data() {
    return {
      user: null,

    }
  },

  mounted() {
    this.user = this.loadUserFromLocalStorage();
  },

  methods: {
    loadUserFromLocalStorage() {
      // try to load user from local storage
      return JSON.parse(localStorage.getItem('user'));
    },

    register(user) {
      return ServerService
        .post('/mms/userprofile/signup', user)
        .then(data => {
          let jwt = data.jwt;

          if (jwt) {
            localStorage.setItem('user', JSON.stringify({ first_name: user.firstName,
                                                          last_name: user.lastName,
                                                          role_id: user.roleId,
                                                          jwt: jwt }));
          }

          return user;
        });
    },

    login(user) {
      return ServerService
        .post('/mms/userprofile/login', user)
        .then(data => {
          let user = data.user;
          let jwt = data.jwt;

          if (user && jwt) {
            user.jwt = jwt;
            localStorage.setItem('user', JSON.stringify(user));
          }

          return user
        });
    },

    logout() {
      localStorage.removeItem('user');
    },

    updateUser(user) {
      localStorage.setItem('user', JSON.stringify(user));
    }
  }
};
