<template>
  <span>
    <Button label="Access Terminal" @click="showDialog = true" />

    <Dialog
      modal
      header="Header"
      v-model:visible="showDialog"
      :pt="{ mask: { style: 'backdrop-filter: blur(2px)' } }">
      <template #container="{ closeCallback }">
        <Card>
          <template #title>Access Terminal</template>
          <template #content>
            <form @submit.prevent="submit">
              <div class="inputfield">
                <Dropdown v-model="record"
                          :options="records"
                          optionLabel="name"
                          placeholder="Select a terminal"
                          class="w-full md:w-14rem" />
              </div>
              <Button type="submit" label="Access" />
              <Button label="close" @click="closeCallback" />
            </form>
          </template>
        </Card>
      </template>
    </Dialog>
  </span>
</template>

<script>
 import Button from 'primevue/button';
 import Dialog from 'primevue/dialog';
 import Card from 'primevue/card';
 import Dropdown from 'primevue/dropdown';

 import 'primevue/resources/themes/saga-blue/theme.css';    // theme
 import 'primevue/resources/primevue.min.css';              // core css
 import 'primeicons/primeicons.css'                         // icons

 export default {
   name: 'AccessTerminal',

   components: { Button, Card, Dialog, Dropdown },

   props: {
     records: { type: Array, required: true }
   },

   data() {
     return {
       showDialog: false,
       record: { },
     };
   },

   mounted() {

   },

   methods: {
     submit() {
       return this.$attrs.onUpdate({ terminalId: this.record.id });
     }
   }
 };

</script>


<style scoped>
 Button {
   margin: 5px;
 }
</style>
