<template>
  <div class="manage-user-profile">
    <Card>
      <template #title>User Profiles</template>
      <template #content>
        <DataTable v-model:selection="selectedUsers" :value="users" dataKey="id" tableStyle="min-width: 50rem">
          <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
          <Column field="first_name" header="First Name"></Column>
          <Column field="last_name" header="Last Name"></Column>
          <Column field="email" header="Email"></Column>
          <Column field="role_id" header="Role"></Column>
          <Column field="status" header="Status"></Column>
        </DataTable>
      </template>
    </Card>
  </div>
</template>

<script>
 import AuthServiceMixins from '@/services/AuthServiceMixins';
 import ServerService from '@/services/ServerService';

 import Card from 'primevue/card';
 import DataTable from 'primevue/datatable';
 import Column from 'primevue/column';

 import 'primevue/resources/themes/saga-blue/theme.css';    // theme
 import 'primevue/resources/primevue.min.css';              // core css
 import 'primeicons/primeicons.css'                         // icons

 export default {
   name: 'ManageUserProfileView',

   mixins: [AuthServiceMixins],
   components: { Card, DataTable, Column },

   data() {
     return {
       users: [],
       selectedUsers: [],
       errorMessage: '',
     };
   },

   mounted() {
     ServerService
       .post('/mms/userprofile/list')
       .then(data => {
         this.users = data;

       }, error => {
         console.log(error);
       });
   },

   methods: {
   }
 };

</script>


<style scoped>
 .manage-user-profile {
   text-align: left;
   padding: 30px;
 }

 .actions {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   color: #2c3e50;
 }

 nav a {
   font-weight: bold;
   color: #2c3e50;
 }

 nav a.router-link-exact-active {
   color: #42b983;
 }
</style>
