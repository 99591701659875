<template>

  <Card>
    <template #title>IP INFO</template>
    <template #content>
      <DataTable v-model:selection="selectedIp" :value="ipRecords" dataKey="id" tableStyle="min-width: 50rem">
        <Column selectionMode="single" headerStyle="width: 3rem"></Column>
	<Column field="isp" header="ISP"></Column>
	<Column field="action" header="Action"></Column>
	<Column field="src_ip" header="IP"></Column>
        <Column field="src_port" header="PORT"></Column>
        <Column field="datetime" header="Date Time"></Column>
        <Column field="country" header="Country"></Column>
        <Column field="region" header="Region"></Column>
	<Column field="city" header="City"></Column>
	<Column field="lat" header="Latitude"></Column>
        <Column field="lon" header="Longitude"></Column>
      </DataTable>
    </template>
  </Card>

  <Card>
    <template #title>Map</template>
    <template #content>
      <div id="gl-container"></div>
    </template>
  </Card>

</template>

<script>
 import _ from 'lodash';
 import ServerService from '@/services/ServerService';

 import Card from 'primevue/card';
 import DataTable from 'primevue/datatable';
 import Column from 'primevue/column'

 import 'primevue/resources/themes/saga-blue/theme.css';    // theme
 import 'primevue/resources/primevue.min.css';              // core css
 import 'primeicons/primeicons.css'                         // icons

 export default {
   name: 'IpGeoInfo',

   components: { Card, DataTable, Column },

   data() {
     return {
       ipRecords: [],
       selectedIp: {},
     };
   },

   mounted() {
     this.loadIpRecords();
   },

   methods: {
     loadIpRecords() {
       ServerService
         .post('/mms/ip/list')
         .then(data => {
           this.ipRecords = data;

         }, error => {
           console.log(error);
         });
     },
     
   }
 };

</script>


<style scoped>
</style>
