import { createRouter, createWebHashHistory } from 'vue-router'
import HomeView from '@/views/HomeView.vue'
import ManageUserProfileView from '@/views/ManageUserProfileView.vue'
import RssFeed from '@/views/RssFeed'
import IpGeoInfo from '@/views/IpGeoInfo'
import TerminalManagement from '@/views/TerminalManagement'
import MissionManagement from '@/views/MissionManagement'
import SignupView from '@/views/Signup.vue'
import LoginView from '@/views/Login.vue'

const routes = [{ path: '/',
                  name: 'home',
                  component: HomeView
                },

                { path: '/userprofile',
                  name: 'userprofile',
                  // route level code-splitting
                  // this generates a separate chunk (about.[hash].js) for this route
                  // which is lazy-loaded when the route is visited.
                  //component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
                  component: ManageUserProfileView
                },

                { path: '/rssfeed',
                  name: 'rssfeed',
                  component: RssFeed
                },
		{ path: '/ip',
                  name: 'ipgeoinfo',
                  component: IpGeoInfo
                },

                { path: '/terminal',
                  name: 'terminal',
                  component: TerminalManagement
                },

                { path: '/mission',
                  name: 'mission',
                  component: MissionManagement
                },

                { path: '/signup',
                  name: 'signup',
                  component: SignupView
                },

                { path: '/login',
                  name: 'login',
                  component: LoginView
                },

                { path: '/logout',
                  name: 'logout',
                  component: LoginView
                }];

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
