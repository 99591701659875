<template>
  <span>
    <Button label="Edit" :disabled="disabled" @click="showDialog = true" />

    <Dialog
      modal
      v-model:visible="showDialog"
      :pt="{ mask: { style: 'backdrop-filter: blur(1px)' } }">

      <template #container="{ closeCallback }">
        <form @submit.prevent="submit">
          <div class="inputfield">
            <InputText type="text" v-model="record.name" placeholder="Terminal Name" required/>
          </div>
          <div class="inputfield">
            <InputText type="text" v-model="record.type" placeholder="Terminal Type" required/>
          </div>
          <Button type="submit" label="Add" />
          <Button label="close" @click="closeCallback" />
        </form>
      </template>
    </Dialog>
  </span>
</template>

<script>
 import Button from 'primevue/button';
 import InputText from 'primevue/inputtext';
 import Dialog from 'primevue/dialog';

 import 'primevue/resources/themes/saga-blue/theme.css';    // theme
 import 'primevue/resources/primevue.min.css';              // core css
 import 'primeicons/primeicons.css'                         // icons

 export default {
   name: 'AddTerminal',

   components: { Button, Dialog, InputText },

   props: {
     disabled: { type: Boolean },
   },
   
   data() {
     return {
       showDialog: false,
       record: { name: null, type: null },
     };
   },

   mounted() {
   },

   methods: {
     submit() {
       return this.$attrs.onUpdate(this.record);
     }
   }
 };

</script>


<style scoped>
 Button {
   margin: 5px;
 }
</style>
