import axios from 'axios';
import _ from 'lodash';
import Defer from '@/services/Defer';

function get(url, requestData, config, defer) {
  if (_.isUndefined(defer)) {
    defer = Defer.create();
  }

  var request = { url: url,
                  requestData: requestData,
                  config: config || {},
                  defer: defer };

  axios
    .get(url, requestData)
    .then(function({ status, data }) {
      try {
        switch (status) {
        case 200:
          if (!_.isObject(data) || _.isEmpty(data)) {
            handleNetworkError(request);

          } else if (data.error) {
            request.defer.reject(data.error);

          } else {
            handleOkResponse(data, request);
          }

          break;

        case 403:
          console.log('bad session');
          break;

        default:
          console.log('error');
        }
      } catch(err) {
        console.log(err);
      }
    });
}

function post(url, requestData, config, defer) {
  if (_.isUndefined(defer)) {
    defer = Defer.create();
  }

  var request = { url: url,
                  requestData: requestData,
                  config: config || {},
                  defer: defer };

  var user = JSON.parse(localStorage.getItem('user'));

  axios
    .create({
      headers: {
        'jwt': `${user?.jwt}`
      }
    })
    .post(url, requestData)
    .then(({ status, data }) => {
      try {
        switch (status) {
        case 200:
          if (!_.isObject(data) || _.isEmpty(data)) {
            handleNetworkError(request);

          } else if (data.error) {
            request.defer.reject(data.error);

          } else {
            handleOkResponse(data, request);
          }

          break;

        case 403:
          console.log('bad session');
          break;

        default:
          console.log('error');
        }

      } catch(err) {
        console.log(err);
      }

    }, (err) => {
      request.defer.reject(err);
    });

  return defer.promise;
}

function handleOkResponse(data, request) {
  request.defer.resolve(request.config.transformResponse
                        ? request.config.transformResponse(data.data)
                        : data.data);
}

function handleNetworkError(request) {
  window.alert('Network Error. Click OK to retry.');
  post(request.url, request.requestData, request.config, request.defer);
}

export default { get, post };
