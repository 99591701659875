<template>
  <div class="home">
    <img alt="Vue logo" src="../assets/logo.png">
    <Welcome v-if="user" :msg="message" />
  </div>
</template>

<script>
 // @ is an alias to /src
 import Welcome from '@/components/Welcome.vue'
 import AuthServiceMixins from '@/services/AuthServiceMixins';

 export default {
   name: 'HomeView',

   components: { Welcome },

   mixins: [AuthServiceMixins],

   data() {
     return {
       message: null,
     }
   },

   mounted() {
     this.message = this.user
                  ? 'Hi ' + this.user.first_name + ' ' + this.user.last_name + '! Welcome to mms.'
                  : 'Welcome.';
   }
 }
</script>
