class Defer {
  constructor() {
    this.promise = new Promise((resolve, reject) => {
      this.resolve = resolve;
      this.reject = reject;
    });
  }
}

let deferImpl = () => new Defer();

export default {
  create() {
    return deferImpl();
  }
};

export function configure(impl) {
  deferImpl = impl;
}
