<template>
  <Card>
    <template #title>Terminals</template>
    <template #content>

      <AccessTerminal
        @update="accessTerminal($event)"
        v-if="user && (1 === user.role_id)"
              :records="searchedTerminals"
      />
      <CreateTerminal @update="createTerminal($event)" v-if="user && (2 === user.role_id)"/>
      <EditTerminal @update="editTerminal($event)" :disabled="true" />

      <DataTable v-model:selection="selectedTerminals" :value="terminals" dataKey="id" tableStyle="min-width: 50rem">
        <Column selectionMode="multiple" headerStyle="width: 3rem"></Column>
        <Column field="name" header="Name"></Column>
        <Column field="type" header="Type"></Column>
        <Column field="status" header="Status"></Column>
      </DataTable>
    </template>
  </Card>
</template>

<script>
 import AuthServiceMixins from '@/services/AuthServiceMixins';
 import TerminalManagerMixins from '@/services/TerminalManagerMixins';
 import ServerService from '@/services/ServerService';

 import Card from 'primevue/card';
 import DataTable from 'primevue/datatable';
 import Column from 'primevue/column';
 import AccessTerminal from './AccessTerminal.vue';
 import CreateTerminal from './CreateTerminal.vue';
 import EditTerminal from './EditTerminal.vue';

 import 'primevue/resources/themes/saga-blue/theme.css';    // theme
 import 'primevue/resources/primevue.min.css';              // core css
 import 'primeicons/primeicons.css'                         // icons

 export default {
   name: 'TerminalManagement',

   mixins: [AuthServiceMixins, TerminalManagerMixins],
   components: { Card, DataTable, Column, AccessTerminal, CreateTerminal, EditTerminal },

   data() {
     return {
       terminals: [],
       selectedTerminals: [],
       searchedTerminals: [],
     };
   },

   mounted() {
     ServerService.post('/mms/terminal/search')
                  .then(list => {
                    this.searchedTerminals = list;
                    this.loadTerminalList();
                  });
   },

   methods: {
     loadTerminalList() {
       this.loadTerminals()
           .then(data => {
             this.terminals = data;

           }, error => {
             console.log(error);
           });
     },

     accessTerminal(record) {
       ServerService
         .post('/mms/terminal/access', record)
         .then(x => {
           this.loadTerminalList();

         }, error => {
           console.log('error', error);
         });
     },

     createTerminal(record) {
       ServerService
         .post('/mms/terminal/create', record)
         .then(x => {
           this.loadTerminalList();

         }, error => {
           console.log('error', error);
         });
     },

     editTerminal(record) {

     },

   }
 };

</script>


<style scoped>
 Button {
   margin: 5px;
 }

 .manage-user-profile {
   text-align: left;
   padding: 30px;
 }

 .actions {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   color: #2c3e50;
 }

 nav a {
   font-weight: bold;
   color: #2c3e50;
 }

 nav a.router-link-exact-active {
   color: #42b983;
 }
</style>
