<template>
  <span>
    <Button label="Add" @click="showDialog = true" />

    <Dialog v-model:visible="showDialog" modal header="Header" :style="{ width: '20rem' }">
      <template #header>
	<span class="font-bold white-space-nowrap">Amy Elsner</span>
      </template>
      <template #container="{ closeCallback }">
        <Card>
          <template #title>Add Mission</template>
          <template #content>
            <form @submit.prevent="submit">
              <div class="inputfield">
                <InputText type="text" v-model="record.name" placeholder="Mission Name" required/>
              </div>
              <Button type="submit" label="Add" />
              <Button label="close" @click="closeCallback" />
            </form>
          </template>
        </Card>
      </template>
    </Dialog>
  </span>
</template>

<script>
 import Button from 'primevue/button';
 import InputText from 'primevue/inputtext';
 import Dialog from 'primevue/dialog';
 import Card from 'primevue/card';

 import 'primevue/resources/themes/saga-blue/theme.css';    // theme
 import 'primevue/resources/primevue.min.css';              // core css
 import 'primeicons/primeicons.css'                         // icons

 export default {
   name: 'AddMission',

   components: { Button, Dialog, InputText, Card },

   data() {
     return {
       showDialog: false,
       record: { name: null },
     };
   },

   mounted() {
   },

   methods: {
     submit() {
       return this.$attrs.onUpdate(this.record);
     }
   }
 };

</script>


<style scoped>
 Button {
   margin: 5px;
 }
</style>
