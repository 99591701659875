import ServerService from './ServerService';
//import router from '@/router';

export default {
  name: 'TerminalManagerMinxins',

  data() {
    return {
    }
  },

  methods: {
    loadTerminals() {
      return ServerService.post('/mms/terminal/list');
    },

    loadRequests(missionId) {
      return ServerService.post('/mms/request/list', { missionId: missionId });
    },

  }
};
