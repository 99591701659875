<template>
  <Card>
    <template #title>User Login</template>
    <template #content>
      <div class="error">
        <span>{{ errorMessage }}</span>
      </div>
      <form @submit.prevent="submit">
        <div>
          <i class="pi pi-user"></i>
          <InputText type="text" v-model="formData.username" placeholder="Username" required/>
        </div>

        <div>
          <i class="pi pi-key"></i>
          <Password v-model="formData.password" placeholder="Password" required/>
        </div>

        <Button label="Login" type="submit" />
      </form>
    </template>
  </Card>
</template>

<script>
 import AuthServiceMixins from '@/services/AuthServiceMixins';
 import router from '@/router';

 import Card from 'primevue/card';
 import InputText from 'primevue/inputtext';
 import Password from 'primevue/password';
 import Button from 'primevue/button';

 import 'primevue/resources/themes/saga-blue/theme.css';    // theme
 import 'primevue/resources/primevue.min.css';              // core css
 import 'primeicons/primeicons.css'                         // icons

 export default {
   name: 'LoginView',

   mixins: [AuthServiceMixins],
   components: { Card, InputText, Password, Button },

   data() {
     return {
       formData: {
         username: '',
         password: ''
       },
       errorMessage: '',
     };
   },
   methods: {
     submit() {
       this.errorMessage = '';

       this.login(this.formData)
           .then(data => {
             window.location.href = '/';

           }, (error) => {
             this.errorMessage = error?.response?.data?.error || 'Login Failed';
           });
     }
   }
 };
</script>

<style scoped>
 /* Add your component-specific styles here if needed */
 .error {
   font-weight: bold;
   color: red;
   margin-bottom: 20px;
 }

 i {
   margin-right: 10px;
 }

 Button {
   margin: 20px;
 }

</style>
