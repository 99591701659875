<template>
  <nav>
    <span>
      <router-link to="/">Home</router-link> |
    </span>

    <span v-if="user && isAdmin(user)">
      <router-link to="/userprofile">Manage User Profiles</router-link> |
    </span>

    <span v-if="user && isAdmin(user)">
      <router-link to="/rssfeed">Rss Feeds</router-link> |
    </span>
    <span v-if="user && isAdmin(user)">
      <router-link to="/ip">IP GEO INFO</router-link> |
    </span>

    <span v-if="user && (isTerminalManager(user) || isTerminalOperator(user))">
      <router-link to="/terminal">Manage Terminals</router-link> |
    </span>

    <span v-if="user && isTerminalManager(user)">
      <router-link to="/mission">Manage Missions</router-link> |
    </span>

    <span v-if="!user">
      <router-link to="/signup">Signup</router-link> |
    </span>

    <span v-if="!user">
      <router-link to="/login">Login</router-link> |
    </span>

    <span v-if="user">
      <a href="javascript:void(0)" @click="logoutUser()">Logout</a>
    </span>
  </nav>
  <router-view/>
</template>

<script>
 import AuthServiceMixins from '@/services/AuthServiceMixins';

 export default {
   name: 'AppView',
   mixins: [AuthServiceMixins],
   data() {
     return {
     }
   },
   methods: {
     logoutUser() {
       this.logout();
       window.location.href = '/';
     },

     isAdmin(user) {
       return 4 == user?.role_id;
     },

     isTerminalManager(user) {
       return [1].includes(user?.role_id);
     },

     isTerminalOperator(user) {
       return [2].includes(user?.role_id);
     },


   }
 };
</script>

<style>
 #app {
   font-family: Avenir, Helvetica, Arial, sans-serif;
   -webkit-font-smoothing: antialiased;
   -moz-osx-font-smoothing: grayscale;
   text-align: center;
   color: #2c3e50;
 }

 nav {
   padding: 30px;
 }

 nav a {
   font-weight: bold;
   color: #2c3e50;
 }

 nav a.router-link-exact-active {
   color: #42b983;
 }
</style>
