<template>
  <span>
    <Button label="Edit" @click="showDialog = true" />

    <Dialog
      modal
      v-model:visible="showDialog"
      :pt="{ mask: { style: 'backdrop-filter: blur(1px)' } }">
      <template #container="{ closeCallback }">
        <Card>
          <template #title>Edit Mission</template>
          <template #content>

            <AddRequest
              :terminals="terminals"
              @update="addRequest($event)"  />

            <DataTable v-model:selection="selectedRequest" :value="requests" dataKey="id" tableStyle="min-width: 50rem">
              <Column selectionMode="single" headerStyle="width: 3rem"></Column>
              <Column field="name" header="Name"></Column>
              <Column field="terminalName" header="Terminal Name"></Column>
              <Column field="beginDate" header="Begin Date"></Column>
              <Column field="endDate" header="End Date"></Column>
              <Column field="cirKbps" header="CIR kbps"></Column>
              <Column field="priority" header="Priority"></Column>
              <Column field="lat" header="Latitude"></Column>
              <Column field="lng" header="Longitude"></Column>
              <Column field="status" header="Status"></Column>
            </DataTable>

            <Button label="close" @click="closeCallback" />
          </template>
        </Card>

      </template>
    </Dialog>
  </span>
</template>

<script>
 import _ from 'lodash';
 import TerminalManagerMixins from '@/services/TerminalManagerMixins';
 import ServerService from '@/services/ServerService';
 import Button from 'primevue/button';
 import Dialog from 'primevue/dialog';
 import DataTable from 'primevue/datatable';
 import Column from 'primevue/column'
 import Card from 'primevue/card';

 import AddRequest from './AddRequest';

 import 'primevue/resources/themes/saga-blue/theme.css';    // theme
 import 'primevue/resources/primevue.min.css';              // core css
 import 'primeicons/primeicons.css'                         // icons

 export default {
   name: 'AddMission',

   mixins: [TerminalManagerMixins],
   components: { Button, Dialog, DataTable, Column, AddRequest, Card },

   props: {
     record: { type: Object, required: true }
   },

   data() {
     return {
       showDialog: false,
       changeRecord: { name: null },
       requests: [],
       terminals: [],
       terminalMap: {},
       selectedRequest: {},
     };
   },

   watch: {
     record(val) {
       this.initChangeRecord(val);
       this.loadTerminals()
           .then(data => {
             this.terminals = data;
             this.terminalMap = _.chain(data)
                                 .keyBy('id')
                                 .mapValues('name')
                                 .value();
             this.loadRequests(this.record.id);
           });
     },
   },

   mounted() {
   },

   methods: {
     initChangeRecord() {
       this.changeRecord = { ...this.record };
     },

     loadRequests(missionId) {
       ServerService
         .post('/mms/request/list', { missionId: missionId })
         .then(data => {
           this.requests = data.map(x => ({ ...x,
                                            terminalName: this.terminalMap[x.terminal_id],
                                            beginDate: x.begin_date,
                                            endDate: x.end_date,
                                            cirKbps: x.cir_kbps }));

         }, error => {
           console.log(error);
         });
     },

     addRequest(record) {
       ServerService
         .post('/mms/request/create', {...record,
                                       missionId: this.record.id })
         .then(data => {
           this.loadRequests(this.record.id);

         }, error => {
           console.log(error);
         });
     },

     submit() {
       return this.$attrs.onUpdate(this.record);
     }
   }
 };

</script>


<style scoped>
 Button {
   margin: 5px;
 }
</style>
