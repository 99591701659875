<template>
  <Card>
    <template #title>User Login</template>
    <template #content>
      <div class="error">
        <span>{{ errorMessage }}</span>
      </div>
      <form @submit.prevent="submit">
        <div class="inputfield">
          <!--label for="firstName">First Name:</label-->
          <InputText type="text" v-model="formData.firstName" placeholder="First Name" required/>
        </div>
        <div class="inputfield">
          <!--label for="lastName">Last Name:</label-->
          <InputText type="text" v-model="formData.lastName" placeholder="Last Name" required/>
        </div>
        <div class="inputfield">
          <!--label for="email">Email:</label-->
          <i class="pi pi-email"></i>
          <InputText type="text" v-model="formData.email" placeholder="Email" required/>
        </div>
        <div class="inputfield">
          <!--label for="username">Username:</label-->
          <InputText type="text" v-model="formData.username" placeholder="Username" required/>
        </div>
        <div class="inputfield">
          <!--label for="password">Password:</label-->
          <Password v-model="formData.password" placeholder="Password" required/>
        </div>
        <div class="inputfield">
          <Dropdown v-model="formData.role"
                    :options="roles"
                    optionLabel="title"
                    placeholder="Select a role"
                    class="w-full md:w-14rem" />
        </div>
        <Button type="submit" label="Signup" />
      </form>
    </template>
  </Card>
</template>

<script>
 import AuthServiceMixins from '@/services/AuthServiceMixins';
 import ServerService from '@/services/ServerService';

 import Card from 'primevue/card';
 import InputText from 'primevue/inputtext';
 import Password from 'primevue/password';
 import Dropdown from 'primevue/dropdown';
 import Button from 'primevue/button';

 import 'primevue/resources/themes/saga-blue/theme.css';    // theme
 import 'primevue/resources/primevue.min.css';              // core css
 import 'primeicons/primeicons.css'                         // icons

 export default {
   name: 'SignupView',

   mixins: [AuthServiceMixins],
   components: { Card, InputText, Password, Dropdown, Button },

   data() {
     return {
       errorMessage: null,
       roles: [],
       formData: {
         firstName: null,
         lastName: null,
         email: null,
         username: null,
         password: null,
         role: null
       }
     };
   },

   mounted() {
     ServerService
       .post('/mms/role/list')
       .then(roles => {
         this.roles = roles;
       });
   },

   methods: {


     submit() {
       this.register({...this.formData, roleId: this.formData.role.id})
           .then(data => {
             //console.log('signup response: ', data);
             //router.push('/');
             window.location.href = '/';
           }, error => {
             this.errorMessage = error?.response?.data?.error || 'Signup Failed';
           });
     }
   }
 };
</script>

<style scoped>
 .inputfield {
   margin-bottom: 5px;
 }

</style>
