import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import PrimeVue from 'primevue/config'
import DialogService from 'primevue/dialogservice';

var app = createApp(App);

/*
 * npm install --save primevue
 * npm install --save primeicons
 */
app.use(PrimeVue, { inputStyle: "filled" });

/*
 * vue add router
 */
app.use(router);
app.use(DialogService);

app.mount('#app')
