<template>
  <span>
    <Button label="Add Mission Request" @click="showDialog = true" />

    <Dialog
      modal
      v-model:visible="showDialog"
      :pt="{ mask: { style: 'backdrop-filter: blur(1px)' } }">

      <template #container="{ closeCallback }">
        <Card>
          <template #title>Add Mission Request</template>
          <template #content>
            <form @submit.prevent="submit">
              <div class="inputfield">
                <InputText type="text" v-model="record.name" placeholder="Request Name" required/>
              </div>
              <div class="inputfield">
                <Dropdown v-model="record.terminal"
                          :options="terminals"
                          optionLabel="name"
                          placeholder="Select a terminal"
                          class="w-full md:w-14rem" />
              </div>
              <div class="inputfield">
                <InputText type="text" v-model="record.beginDate" placeholder="Begin Date" required/>
              </div>
              <div class="inputfield">
                <InputText type="text" v-model="record.endDate" placeholder="End Date" required/>
              </div>
              <div class="inputfield">
                <InputText type="text" v-model="record.cirKbps" placeholder="CIR kbps" required/>
              </div>
              <div class="inputfield">
                <InputText type="text" v-model="record.priority" placeholder="Priority" required/>
              </div>
              <div class="inputfield">
                <InputText type="text" v-model="record.latency" placeholder="Latency" required/>
              </div>
              <div class="inputfield">
                <InputText type="text" v-model="record.lat" placeholder="Latitude" required/>
              </div>
              <div class="inputfield">
                <InputText type="text" v-model="record.lng" placeholder="Longitude" required/>
              </div>
              <Button type="submit" label="Add" />
              <Button label="close" @click="closeCallback" />
            </form>
          </template>
        </Card>
      </template>
    </Dialog>
  </span>
</template>

<script>
 import TerminalManagerMixins from '@/services/TerminalManagerMixins';
 import Button from 'primevue/button';
 import InputText from 'primevue/inputtext';
 import Dialog from 'primevue/dialog';
 import Dropdown from 'primevue/dropdown';
 import Card from 'primevue/card';

 import 'primevue/resources/themes/saga-blue/theme.css';    // theme
 import 'primevue/resources/primevue.min.css';              // core css
 import 'primeicons/primeicons.css'                         // icons

 export default {
   name: 'AddRequest',

   mixins: [TerminalManagerMixins],
   components: { Button, Dialog, InputText, Dropdown, Card },

   props: {
     terminals: { type: Array, required: true }
   },

   data() {
     return {
       showDialog: false,
       record: { name: null,
                 terminal: null,
                 beginDate: null,
                 endDate: null,
                 cirKbpsl: null,
                 priority: null,
                 latency: null,
                 lat: null,
                 lng: null },
     };
   },

   methods: {
     submit() {
       return this.$attrs.onUpdate({ ...this.record,
                                     terminalId: this.record.terminal.id });
     }
   }
 };

</script>


<style scoped>
 Button {
   margin: 5px;
 }
</style>
