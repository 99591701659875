<template>

  <Card>
    <template #title>Missions</template>
    <template #content>

      <AddMission @update="addMission($event)" />
      <EditMission
        :record="selectedMission"
        @update="addMission($event)" />


      <DataTable v-model:selection="selectedMission" :value="missions" dataKey="id" tableStyle="min-width: 50rem">
        <Column selectionMode="single" headerStyle="width: 3rem"></Column>
        <Column field="name" header="Name"></Column>
        <Column field="status" header="Status"></Column>
      </DataTable>
    </template>
  </Card>

  <Card>
    <template #title>Mission Requests</template>
    <template #content>
      <DataTable v-model:selection="selectedRequest" :value="requests" dataKey="id" tableStyle="min-width: 50rem">
        <Column selectionMode="single" headerStyle="width: 3rem"></Column>
        <Column field="name" header="Name"></Column>
        <Column field="terminalName" header="Terminal Name"></Column>
        <Column field="beginDate" header="Begin Date"></Column>
        <Column field="endDate" header="End Date"></Column>
        <Column field="cirKbps" header="CIR kbps"></Column>
        <Column field="priority" header="Priority"></Column>
        <Column field="lat" header="Latitude"></Column>
        <Column field="lng" header="Longitude"></Column>
        <Column field="status" header="Status"></Column>
      </DataTable>
    </template>
  </Card>

  <Card>
    <template #title>Earth</template>
    <template #content>
      <div id="gl-container"></div>
    </template>
  </Card>

</template>

<script>
 import _ from 'lodash';
 import TerminalManagerMixins from '@/services/TerminalManagerMixins';
 import AuthServiceMixins from '@/services/AuthServiceMixins';
 import ServerService from '@/services/ServerService';

 import * as THREE from 'three';
 import { OrbitControls } from 'three/addons/controls/OrbitControls.js';
 import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';


 import Card from 'primevue/card';
 import DataTable from 'primevue/datatable';
 import Column from 'primevue/column'

 import AddMission from './AddMission.vue';
 import EditMission from './EditMission.vue';

 import 'primevue/resources/themes/saga-blue/theme.css';    // theme
 import 'primevue/resources/primevue.min.css';              // core css
 import 'primeicons/primeicons.css'                         // icons

 export default {
   name: 'MissionManagement',

   mixins: [AuthServiceMixins, TerminalManagerMixins],
   components: { Card, DataTable, Column, AddMission, EditMission },

   data() {
     return {
       missions: [],
       selectedMission: {},
       selectedRequest: {},

       requests: [],
       terminals: [],
       terminalMap: {},
     };
   },

   mounted() {
     this.loadMissions();
     this.createEarth();
   },

   watch: {
     selectedMission(val) {
       this.loadTerminals()
           .then(data => {
             this.terminals = data;
             this.terminalMap = _.chain(data)
                                 .keyBy('id')
                                 .mapValues('name')
                                 .value();
             this.loadRequests(val.id)
                 .then(x => {
                   this.requests = x.map(y => ({ ...y,
                                                 terminalName: this.terminalMap[y.terminal_id],
                                                 beginDate: y.begin_date,
                                                 endDate: y.end_date,
                                                 cirKbps: y.cir_kbps }));
                 });
           });
     }
   },

   methods: {
     loadMissions() {
       ServerService
         .post('/mms/mission/list')
         .then(data => {
           this.missions = data;

         }, error => {
           console.log(error);
         });
     },

     addMission(record) {
       ServerService
         .post('/mms/mission/create', record)
         .then(x => {
           this.loadMissions();

         }, error => {
           console.log('error', error);
         });
     },

     createEarth() {
       const scene = new THREE.Scene();
       const camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 0.1, 1000);
       const renderer = new THREE.WebGLRenderer();
       renderer.setSize(window.innerWidth/1.5, window.innerHeight/1.5);
       document.getElementById('gl-container').appendChild(renderer.domElement);

       const cloudGeometry = new THREE.SphereGeometry(105, 50, 50 );
       const cloudMaterial = new THREE.MeshBasicMaterial( { color: 0xff0000, wireframe: true } );
       //const cloudMaterial = new THREE.MeshBasicMaterial({
       //  map: new THREE.TextureLoader().load('https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/planets/earth_clouds_2048.png'),
       //  opacity: 3,
       //  alphaHash: true
       //});
       const cloud = new THREE.Mesh( cloudGeometry, cloudMaterial );
       scene.add( cloud );

       // Earth sphere
       const earthGeometry = new THREE.SphereGeometry(90, 50, 50); // 50, 60, 40
       const earthTexture = new THREE.TextureLoader().load(
         'https://raw.githubusercontent.com/mrdoob/three.js/master/examples/textures/planets/earth_atmos_4096.jpg'
       );
       const earthMaterial = new THREE.MeshBasicMaterial({ map: earthTexture });
       const earth = new THREE.Mesh(earthGeometry, earthMaterial);
       scene.add(earth);

       // Camera positioning
       camera.position.z = 150;

       // Basic interactivity
       const controls = new OrbitControls(camera, renderer.domElement);

       // Render loop
       function animate() {
         requestAnimationFrame(animate);
         controls.update();

         //earth.rotation.x += 0.01;
         //earth.rotation.y += 0.01;

         //generateTarget();

         renderer.render(scene, camera);
       }

       // generate a random point on a sphere
       function generateTarget() {
         earth.theta = Math.random() * Math.PI * 2;
         earth.phi = Math.acos( ( 2 * Math.random() ) - 1 );
         earth.radius = 2;

         cloud.position.setFromSpherical( earth );

         // compute target rotation
         //rotationMatrix.lookAt( target.position, mesh.position, mesh.up );
         //targetQuaternion.setFromRotationMatrix( rotationMatrix );

         //setTimeout( generateTarget, 2000 );
       }

       animate();
     }

   }
 };

</script>


<style scoped>
</style>
