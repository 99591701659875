<template>
  <div>
    <h2>Latest News</h2>
    <ul>
      <li v-for="item in news" :key="item.guid">

        <Card class="feed-card">
          <template #title>
            <a :href="item.link" class="feed-link" target="_blank">{{ item.title }}</a>
          </template>

          <template #content>
            <div>{{ item.pubDate }}</div>
            <p class="m-0">
              {{ item.content || item.description }}
            </p>
          </template>
        </Card>

	<br/>
	
      </li>
    </ul>
  </div>
</template>

<script>
 import _ from 'lodash';
 import ServerService from '@/services/ServerService';

 import Card from 'primevue/card';

 export default {
   components: { Card },

   data() {
     return {
       news: [],
     };
   },

   mounted() {
     this.fetchNews();
   },

   methods: {
     fetchNews() {
       ServerService
         .post('/mms/rssfeed/list')
         .then(data => {
           this.news = data;

         }, error => {
           console.log(error);
         });
     },
   },

 };
</script>

<style scoped>
.feed-card {
  max-width: 600px; /* Set your desired maximum width */
  margin: 0 auto;   /* Center the card horizontally */
 }

 .feed-link {
   color: #aaaaaa;
 }
</style>
